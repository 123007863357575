module.exports = [{
      plugin: require('../plugins/wm-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.dirtt.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DIRTT","short_name":"DIRTT","description":"DIRTT is more than modular walls. This personalized interior system uses virtual reality filled with data to build the perfect space. Yep. It's a big deal.","start_url":"/","background_color":"#1E1E1E","theme_color":"#1E1E1E","display":"standalone","icon":"src/images/icon.png","include_favicon":false,"icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"553d115d1ae954e6637a4e2a0029d8f5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KBVNSCZ","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"() => {\n          const queryStrings =\n            (document && document.location && document.location.search) || {};\n\n          window.utms = queryStrings;\n\n          const queriesObj = queryStrings\n            ? Object.fromEntries(new URLSearchParams(queryStrings))\n            : {};\n\n          return {\n            platform: `gatsby`,\n            ...queriesObj\n          };\n        }"},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
